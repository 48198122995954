import { useReducer, useEffect } from "react"
import { useAuth } from "../contexts/UserContext"
import { database } from "../firebase"

const ACTIONS = {
  SELECT_PAGE: "select-page",
  UPDATE_PAGE: "update-page",
  SET_PAGE_LINKS: "set-page-links",
}

export const ROOT_PAGE = { name: "", id: null }

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTIONS.SELECT_PAGE:
      return {
        pageId: payload.pageId,
        page: payload.page,
        pageLinks: [],
      }
    case ACTIONS.UPDATE_PAGE:
      return {
        ...state,
        page: payload.page,
      }
    case ACTIONS.SET_PAGE_LINKS:
      return {
        ...state,
        pageLinks: payload.pageLinks,
      }
    default:
      return state
  }
}

export function usePage(pageId = null, page = null, pageLinks = null) {
  const [state, dispatch] = useReducer(reducer, {
    pageId,
    page,
    pageLinks: [],
  })
  const { currentUser } = useAuth()

  useEffect(() => {
    dispatch({ type: ACTIONS.SELECT_PAGE, payload: { pageId, page } })
  }, [pageId, page])

  useEffect(() => {
    if (pageId == null) {
      return dispatch({
        type: ACTIONS.UPDATE_PAGE,
        payload: { page: ROOT_PAGE },
      })
    }

    database.pages
      .doc(pageId)
      .get()
      .then(doc => {
        dispatch({
          type: ACTIONS.UPDATE_PAGE,
          payload: { page: database.formatDoc(doc) },
        })
      })
      .catch(() => {
        dispatch({
          type: ACTIONS.UPDATE_PAGE,
          payload: { page: ROOT_PAGE },
        })
      })
  }, [pageId])

  useEffect(() => {
    return database.pages
      
      .doc(pageId)
      .collection('links')
      .orderBy("order")
      .onSnapshot(snapshot => {
        dispatch({
          type: ACTIONS.SET_PAGE_LINKS,
          payload: { pageLinks: snapshot.docs.map(database.formatDoc) },
        })
      })
  }, [pageId, currentUser])

  return state
}
